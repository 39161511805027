.eamCard .ant-card {
    border: 1px solid #1a4c7e;
    margin-top: 10px;
}

.eamCard .ant-card-head {
    color: #fff;
    background: #1a4c7e
}

.notify {
    border-radius: 10px !important;
    font-size: 15px !important;
}

.ant-input::placeholder {
    color: #808080 !important;
}