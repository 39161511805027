.flex-container {
    border: 2px solid transparent;
    width: 100%;
    height: 20%;
    align-items: center;
    display: flex;
    align-self: center;
    justify-content: center;
    margin-top: -23px;
    margin-bottom: -23px;
}

.flex-item {
    width: 18%;
    height: 60%;
    text-align: center;
    background-color: rgb(18, 59, 100);
    border: 1px solid #ccc;
}