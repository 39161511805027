.claude-question-container {
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 24px;
    width: 80%;
    border: 1px dashed #9f9f9f;
}

.page-header {
    margin-bottom: 24px;
}

.page-title {
    color: #1890ff;
    margin-bottom: 8px;
}

.page-description {
    font-size: 16px;
    color: #a0a0a0;
}

.question-area {
    background-color: #0b2640;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
}

.question-text {
    font-size: 18px;
    font-weight: 1000;
    margin-bottom: 10px;
    color: white;
}

.input-area {
    margin-bottom: 14px;
}

.submit-button {
    width: 100%;
}

.response-area {
    background-color: #003a8c;
    border-radius: 8px;
    padding: 24px;
    margin-top: 32px;
}

.response-header {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
    color: #1890ff;
}

.response-content {
    font-size: 16px;
    white-space: pre-wrap;
    color: #ffffff;
}

.error-message {
    color: #ff4d4f;
    margin-top: 16px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

@media (max-width: 576px) {
    .claude-question-container {
        padding: 16px;
    }

    .page-title {
        font-size: 24px;
    }

    .question-text {
        font-size: 16px;
    }

    .response-header {
        font-size: 16px;
    }

    .response-content {
        font-size: 14px;
    }
}

.option-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* Spacing between boxes */
}

.optionbox {
    /* flex: 1 1 200px; */
    /* Grow, shrink, and base width */
    min-width: 50px;
    min-height: 40px;
    /* Fixed height, adjust as needed */
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .optionbox {
        flex-basis: 100%;
        /* Full width on small screens */
    }
}

.customSelectOption {
    /* margin: 8px 8px; */
    background: #1a3652;
    color: #fff;
    padding: 10px 24px;
    border-radius: 5px;
    /* font-weight: bold;
    font-size: 17px; */
    font-size: 15px; 
}

.detailsDiv {
    margin: 10px 0px 10px 0px;
    border: 1px dashed grey;
    padding: 20px;
    color: white;
    font-size: 16px;
    background: #0b2640;
}

.detailsTitle {
    color: #ffab0f;
    font-weight: bold;
}

.containerDynamicGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 100%;
    margin: 0 auto;
    line-height: 0px;
}

.item {
    flex-basis: calc(49% - 8px);
    padding: 5px 16px;
    background-color: #ffab0f;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    font-size: 14px;
}

.highlighted {
    background-color: #ffff99;
}

@media (max-width: 600px) {
    .item {
        flex-basis: 100%;
    }
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.loading-text {
    animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}
.submitFooter {
	padding: 13px 0px 0px 0px;
	cursor: pointer;
	color: #ffab0f;
	font-weight: bold;
	border-top: 1px dashed #ffab0f;
}