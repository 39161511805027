body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
.ant-layout-header {
  height: 64px;
  padding: -1px 50px;
  line-height: 64px;
  background: #001529;
} */

.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 4px 4px 0 0;
  background: #384d62;
}

.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated {
  display: flex !important;
}

.ant-table-fixed-left {
  z-index: 0 !important;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: initial !important;
}

.ant-select-arrow {
  margin-top: 0px !important;
}

/* Table CSS */
.downtimeTable tr {
  background-color: #011931 !important;
  color: black !important;
  text-align: center !important;
}

.downtimeTable th {
  color: white !important;
  font-weight: 700 !important;
  text-align: center !important;
}

.downtimeTable th {
  background-color: #214972 !important;
  font-weight: bold !important;
}

.downtimeTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.downtimeTable tr:nth-child(even) {
  background: #7a8793 !important;
}

.downtimeTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}

.ant-btn-primary {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff !important;
  ;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-danger {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
}

.ant-switch {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-switch-checked {
  background-color: #1890ff !important;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {

  vertical-align: baseline !important;
}

.ant-select-selection-item {
  color: black;
}

.ant-tabs {
  color: #012257;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: rgb(255, 255, 255);
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: rgb(255, 255, 255);
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: rgb(255, 255, 255);
}

.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: white;
  border: 0;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
  color: rgb(26, 54, 82);
}
/* .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
} */