
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgb(208, 213, 219);
  font-weight: 500;
}

.my-custom-class .p {
  margin: 0;
  font-size: 16px;
}

.my-custom-class .code {
  background: #E0F0FF;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
} 